var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('swiper',{staticClass:"swiper",attrs:{"options":{
      loop: true,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    }}},[_c('swiper-slide',{staticClass:"text-center"},[_c('img',{attrs:{"src":"WhatsApp Image 2024-03-18 at 09.28.20_85f6f5e5.jpg"}})]),_c('swiper-slide',{staticClass:"text-center"},[_c('router-link',{attrs:{"to":{ name: 'SMEGrant' }}},[_c('img',{attrs:{"src":"TBS-Digitalisation-grant-website-banner_991-x-331_C-1140x380.jpg"}})])],1),_c('swiper-slide',{staticClass:"text-center"},[_c('img',{attrs:{"src":"slideshow-02-1140x380.jpg"}})]),_c('swiper-slide',{staticClass:"text-center"},[_c('img',{attrs:{"src":"slideshow-03-1140x380.jpg"}})]),_c('swiper-slide',{staticClass:"text-center"},[_c('img',{attrs:{"src":"slideshow-04-1140x380.jpg"}})]),_c('div',{staticClass:"swiper-pagination",attrs:{"slot":"pagination"},slot:"pagination"}),_c('div',{staticClass:"swiper-button-prev",attrs:{"slot":"button-prev"},slot:"button-prev"}),_c('div',{staticClass:"swiper-button-next",attrs:{"slot":"button-next"},slot:"button-next"})],1),_vm._m(0),_c('div',{staticClass:"section-home-product-line",staticStyle:{"padding-top":"50px"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('swiper',{attrs:{"options":_vm.swiperOptions}},[_c('swiper-slide',[_c('img',{attrs:{"src":"https://play-lh.googleusercontent.com/kN6VpJWo5nmtcTvk9-0os7TFhz-JAM5-8S09E3mrM56Kp9Ov6EfeO36u9QHU56deJwQ=s180-rw"}})]),_c('swiper-slide',[_c('img',{attrs:{"src":"https://play-lh.googleusercontent.com/Fi8uPEPauvQruCyTb6l2Hn9FpAQddx0l4-2D6SXaGmSEAfYxoDiVTc5xGxZzrydN7hs=s180-rw"}})]),_c('swiper-slide',[_c('img',{attrs:{"src":"https://play-lh.googleusercontent.com/M9cHnal9aC6q5DQzUw8UC4YbX611S2FjnoPEwhfBCFE-ZO1Q4Zq61SHcq7mLDZ0gwqk=s180-rw"}})]),_c('swiper-slide',[_c('img',{attrs:{"src":"fastlane.png"}})]),_c('swiper-slide',[_c('img',{attrs:{"src":"help-academy.png"}})])],1)],1)])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section-home-product-line",staticStyle:{"padding-top":"50px"}},[_c('div',{staticClass:"container px-0"},[_c('div',{staticClass:"row mx-0"},[_c('div',{staticClass:"col-md-6 col-xs-12 mb-3 px-0 px-sm-2"},[_c('img',{attrs:{"src":"TBS-Digitalisation-grant_1920-x-1080.jpg"}})]),_c('div',{staticClass:"col-md-6 col-xs-12 mb-3 px-0 px-sm-2"},[_c('img',{attrs:{"src":"Esales Image sample .jpg"}})])]),_c('div',{staticClass:"row mx-0"})])])}]

export { render, staticRenderFns }