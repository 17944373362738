<template>
  <div>
    <swiper
      class="swiper"
      :options="{
        loop: true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }"
    >
      <swiper-slide class="text-center">
        <img src="WhatsApp Image 2024-03-18 at 09.28.20_85f6f5e5.jpg"
      /></swiper-slide>
      <swiper-slide class="text-center">
        <router-link :to="{ name: 'SMEGrant' }">
          <img
            src="TBS-Digitalisation-grant-website-banner_991-x-331_C-1140x380.jpg"
        /></router-link>
      </swiper-slide>
      <swiper-slide class="text-center">
        <img src="slideshow-02-1140x380.jpg"
      /></swiper-slide>
      <swiper-slide class="text-center">
        <img src="slideshow-03-1140x380.jpg"
      /></swiper-slide>
      <swiper-slide class="text-center">
        <img src="slideshow-04-1140x380.jpg"
      /></swiper-slide>

      <div class="swiper-pagination" slot="pagination"></div>
      <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div>
    </swiper>

    <div class="section-home-product-line" style="padding-top: 50px;">
      <div class="container px-0">
        <div class="row mx-0">
          <div class="col-md-6 col-xs-12 mb-3 px-0 px-sm-2">
            <img src="TBS-Digitalisation-grant_1920-x-1080.jpg" />
          </div>
          <!-- <div class="col-md-6 col-xs-12  mb-3 px-0 px-sm-2">
            <img src="epandu Downloade Sam.jpg" />
          </div> -->
          <div class="col-md-6 col-xs-12 mb-3 px-0 px-sm-2">
            <img src="Esales Image sample .jpg" />
          </div>
          <!-- <div class="col-md-6 col-xs-12  mb-3 px-0 px-sm-2">
            <img src="FPX image .jpg" />
          </div> -->
        </div>
        <div class="row mx-0"></div>
      </div>
    </div>

    <div class="section-home-product-line" style="padding-top: 50px;">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <swiper :options="swiperOptions">
              <swiper-slide>
                <img
                  src="https://play-lh.googleusercontent.com/kN6VpJWo5nmtcTvk9-0os7TFhz-JAM5-8S09E3mrM56Kp9Ov6EfeO36u9QHU56deJwQ=s180-rw"
                />
              </swiper-slide>
              <swiper-slide>
                <img
                  src="https://play-lh.googleusercontent.com/Fi8uPEPauvQruCyTb6l2Hn9FpAQddx0l4-2D6SXaGmSEAfYxoDiVTc5xGxZzrydN7hs=s180-rw"
                /> </swiper-slide
              ><swiper-slide>
                <img
                  src="https://play-lh.googleusercontent.com/M9cHnal9aC6q5DQzUw8UC4YbX611S2FjnoPEwhfBCFE-ZO1Q4Zq61SHcq7mLDZ0gwqk=s180-rw"
                />
              </swiper-slide>
              <swiper-slide> <img src="fastlane.png" /> </swiper-slide
              ><swiper-slide>
                <img src="help-academy.png" />
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import {
  Swiper as SwiperClass,
  Pagination,
  Navigation,
  Autoplay
} from "swiper/swiper.esm.js";
import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter";

import "swiper/swiper-bundle.min.css";

SwiperClass.use([Pagination, Navigation, Autoplay]);
const { directive, Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass);

export default {
  name: "Home",
  data() {
    return {
      slide: 0,
      sliding: null,
      window: window,
      swiperOptions: {
        slidesPerView: 5,
        loop: true,
        breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 5,
            spaceBetween: 20
          },
          540: {
            slidesPerView: 5,
            spaceBetween: 30
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 5,
            spaceBetween: 40
          },
          960: {
            slidesPerView: 5,
            spaceBetween: 100
          }
        },
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        }
      }
    };
  },
  components: {
    Swiper,
    SwiperSlide
  },
  directives: {
    swiper: directive
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    }
  }
};
</script>
